.card {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 352px;
    min-height: 151px;
    padding: 16px 14px;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
	font-family: 'MTSSans';
  
    @media (max-width: 663px) {
      width: 332px;
    }
    &__title {
      font-size: 17px;
      font-weight: bold;
      line-height: 24px;
    }
  
    &__description {
      font-size: 14px;
      line-height: 20px;
      flex-grow: 1;
	  width: 50%;
    }
  
    &__price {
      display: flex;
      width: 100%;
      justify-self: flex-end;
      .sub {
        position: relative;
        top: 3px;
      }
      button {
        justify-self: flex-end;
        margin-left: auto;
        width: 140px;
      }
      .main {
        font-size: 32px;
        font-weight: bold;
        line-height: 120%;
        margin-right: 8px;
        color: var(--bg-accent);
      }
  
      .tariff {
        font-size: 14px;
        line-height: 20px;
        color: var(--bg-accent);
      }
  
      .old-price {
        font-size: 12px;
        line-height: 16px;
		text-decoration: line-through;
      }
  
    }
  
    .age {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 24px;
      height: 24px;
      background-image: url("age.svg");
      transform: scale(.8);
  
      &__count {
        font-size: 12px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  
  }