.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &-wrapper {
    height: min-content;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
}

.card {
  cursor: default;
  background-size: auto 100%;
  height: 194px;
  margin-right: 12px;
}

.small-card {
  height: 194px;
}

.subscription__type {
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  padding: 0 16px;
  border-radius: 6px;
  background-color: var(--bg-tetriary);
}

.card-wrapper {
  width: 100%;
  overflow: hidden;
}

.type label {
  font-size: 14px;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 300px;
  gap: 10px;
}

@media (max-width: 663px) {
  .content {
    flex-direction: column;
    padding-top: 40px;

    &-wrapper {
      flex-direction: column;
      width: 332px;
    }
  }
  .card {
    margin-bottom: 26px;
  }
  .actions {
    height: 192px;
  }
}