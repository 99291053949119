.kion-button {
  font-family: MTSSans, Arial, sans-serif;
  background: var(--bg-accent);
  color: var(--text-form-primary);
  border: 1px solid var(--bg-accent);
  transition: {
    property: border-color, background-color;
    duration: .3s;
    timing-function: ease-in-out;
  };
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border-radius: 6px;
  height: 40px;
  width: 100%;
  outline: none;

  &:hover, &:focus, &:active {
    background: var(--bg-accent-hover);
    border-color: var(--bg-accent-hover);
  }
  &.negative {
    color: var(--text-inverted);
    background: var(--web-tetriary-blue);
    border-color: var(--web-tetriary-blue);
  }
  &[disabled], &.disabled {
    cursor: default;
    background: var(--bg-tetriary);
    color: var(--text-tetriary);
    border-color: var(--bg-tetriary);
  }
}