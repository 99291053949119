.content {
	display: flex;
	flex-direction: column;
	gap: 40px;
	padding: 0 24px;
	padding-top: 106px;
	max-width: 1104px;
	width: 100%;

	.titleWrapper {
		display: flex;
		flex-direction: column;

		h2 {
			text-align: center;
			color: #FAFAFA;
			font-family: 'MTSWideMedium';
		  }
	}

	.subscriptionList {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 36px;
		max-width: 780px;
		margin: 0 auto;
		width: 100%;
	}
}
