html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
}


ol,
ul {
	list-style: none;
}

blockquote,
q {
	quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

/* End of Eric Meyer's CSS Reset */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
	display: block;
}

p {
	margin: 0
}

input,
button,
select,
textarea,
a {
	outline: 0 !important;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

button {
	border: none;
	background-color: transparent;
	padding: 0;
	font-family: inherit;
}

img {
	max-width: 100%;
	height: auto
}

svg,
img {
	vertical-align: bottom;
}

a {
	display: inline-block;
	text-decoration: none;
}

@font-face {
	font-family: 'MTSWideMedium';
	src: url('fonts/wide/MTSWide-Medium/MTSWide-Medium.eot');
	src:
		url('fonts/wide/MTSWide-Medium/MTSWide-Medium.eot?#iefix') format('embedded-opentype'),
		url('fonts/wide/MTSWide-Medium/MTSWide-Medium.woff2') format('woff2'),
		url('fonts/wide/MTSWide-Medium/MTSWide-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'MTSCompactBold';
	src: url('fonts/Compact/MTSCompact-Bold/MTSCompact-Bold.eot');
	src:
		url('fonts/Compact/MTSCompact-Bold/MTSCompact-Bold.eot?#iefix') format('embedded-opentype'),
		url('fonts/Compact/MTSCompact-Bold/MTSCompact-Bold.woff2') format('woff2'),
		url('fonts/Compact/MTSCompact-Bold/MTSCompact-Bold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'MTSCompactMedium';
	src: url('fonts/Compact/MTSCompact-Medium/MTSCompact-Medium.eot');
	src:
		url('fonts/Compact/MTSCompact-Medium/MTSCompact-Medium.eot?#iefix') format('embedded-opentype'),
		url('fonts/Compact/MTSCompact-Medium/MTSCompact-Medium.woff2') format('woff2'),
		url('fonts/Compact/MTSCompact-Medium/MTSCompact-Medium.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'MTSCompactRegular';
	src: url('fonts/Compact/MTSCompact-Regular/MTSCompact-Regular.eot');
	src:
		url('fonts/Compact/MTSCompact-Regular/MTSCompact-Regular.eot?#iefix') format('embedded-opentype'),
		url('fonts/Compact/MTSCompact-Regular/MTSCompact-Regular.woff2') format('woff2'),
		url('fonts/Compact/MTSCompact-Regular/MTSCompact-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'MTSTextRegular';
	src: url('fonts/Text/MTSText-Regular/MTSText-Regular.eot');
	src:
		url('fonts/Text/MTSText-Regular/MTSText-Regular.eot?#iefix') format('embedded-opentype'),
		url('fonts/Text/MTSText-Regular/MTSText-Regular.woff2') format('woff2'),
		url('fonts/Text/MTSText-Regular/MTSText-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'MTSSans';
  src: 
		url('fonts/mts-sans/MTSSans-Regular.woff2') format('woff2'),
    url('fonts/mts-sans/MTSSans-Regular.woff') format('woff');
  font-style: normal;
  font-weight: normal;
}

* {
    --bg-primary: #fff;
    --bg-tetriary: #284059;
    --bg-accent: #f4b30c;
    --bg-accent-hover: #d79e0d;
    --input-border: #e2e5eb;
    --text-inverted: #fff;
    --text-form-primary: #001424;
    --text-form-secondary: #122840;
    --text-tetriary: #93a8bf;
    --text-link: #41adef;
    --warn: #e06400;
    --web-secondary-card-bg: #001a32;
    --web-primary-bright-blue: #45b7fc;
    --web-primary-blue: #001424;
    --web-tetriary-blue: #284059;
    --web-primary-gray: #677f99;
    --web-secondary-gray: #849ab2;
    --web-primary-blue-rgba: 0,20,36;
    --web-modal-bg-gradient: linear-gradient(180.52deg,#0e2339 0.45%,#132f49 38.74%);
    --shadow: 0px 4px 24px rgba(0,0,0,0.12),0px 8px 16px rgba(0,0,0,0.08);
    --shadow-height: 0px 4px 70px rgba(0,0,0,0.3),0px 12px 20px rgba(0,0,0,0.14);
}


body {
	width: 100%;
	background: #040518;
	font-size: 20px;
	line-height: 28px;
	color: white;
	font-family: 'MTSWideMedium';
	overflow-x: hidden;
}

.global_wrapper {
	display: flex;
	flex-direction: column;
	gap: 70px;

	@media (max-width: 1199.98px) {
		gap: 46px;
	}

	@media (max-width: 725px) {
		gap: 35px;
	}

	@media (max-width: 469px) {
		gap: 27px;
	}
}

.wrapper {
	width: 1095px;
	margin: 0 auto;

	@media (max-width: 1199.98px) {
		width: 721px;
	}

	@media (max-width: 725px) {
		width: 100%;
	}
}

.header {
	background: #040518;
	padding: 16px 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000;

	@media (max-width: 725px) {
		padding: 13px 16px;
	}

	.wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		a {
			@media (max-width: 725px) {
				scale: 0.6;
				margin-left: -5%;
			}

			@media (max-width: 469px) {
				scale: 0.5;
				margin-left: -11%;
			}
		}

		.button {
			width: 207px;
			margin-top: 0;

			@media (max-width: 1199.98px) {
				max-height: 38px;
				min-height: 38px;
			}

			@media (max-width: 725px) {
				width: 140px;
			}
		}
	}
}

.f_screen {
	position: relative;
	display: flex;
	background: #040518;
	width: 100%;

	.fullWWrapper {
		width: 100%;

		@media (max-width: 3840px) and (min-width: 1921px) {
			width: 1920px;
		}

		@media (max-width: 469px) {
			padding: 0!important;
		}
	}

	.wrapper {
		display: flex;
		justify-content: center;
		margin: 146px auto auto auto;
		z-index: 1;

		@media (max-width: 1199.98px) {
			margin: 122px auto auto auto;
		}

		@media (max-width: 725px) {
			padding: 0 16px;
		}

		.button {
			display: none;

			@media (max-width: 725px) {
				display: flex;
				position: relative;
				z-index: 999;
			}
		}

		.blackout {
			width: 1585.105px;
			height: 847.108px;
			transform: rotate(-8.943deg);
			flex-shrink: 0;
			position: absolute;
			z-index: 99;
			top: 5%;
			left: 0%;

			@media (max-width: 3840px) and (min-width: 1921px) {
				width: 1685.105px;
				top: 6%;
				left: 12%;
			}

			@media (max-width: 1199.98px) {
				width: 1019.623px;
				height: 578.256px;
				top: 9%;
				left: -3%;
			}

			@media (max-width: 725px) {
				width: 776.189px;
				height: 527.061px;
				top: 6%;
				left: -2%;
			}

			@media (max-width: 469px) {
				width: 532.755px;
				height: 375.866px;
			}
		}

		.blackout::after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			border-radius: 20px;
			background: radial-gradient(80% 94.98% at 96.02% 16.93%, rgba(4, 5, 24, 0.00) 0%, #040518 100%);
			pointer-events: none;
		}

		.background {
			display: flex;
			position: relative;
			overflow: hidden;
			width: 100%;
			border-radius: 24px;

			@media (max-width: 1199.98px) {
				border-radius: 12.7px;
			}

			@media (max-width: 725px) {
				border-radius: 8.25px;
			}

			.background-image {
				display: block;
				width: 100%;
			}

			.content {
				width: 100%;
				padding: 20px;
				position: absolute;
				top: 41%;
				z-index: 999;
				display: flex;
				flex-direction: row;
				align-items: flex-end;

				@media (max-width: 1199.98px) {
					padding: 24px;
					top: 34%;
				}

				@media (max-width: 725px) {
					padding: 15px;
					top: 32%;
				}

				@media (max-width: 469px) {
					padding: 10px;
					top: 0;
					height: 100%;
					flex-direction: column;
					align-items: flex-start;
					justify-content: space-between;
				}

				.top_block {
					display: none;
					color: rgba(250, 250, 250, 0.3);
					font-size: 12px;
					line-height: 16px;
					font-weight: 500;

					@media (max-width: 469px) {
						display: block;
					}
				}

				.left_block {
					display: flex;
					flex-direction: row;
					align-items: flex-end;
					gap: 20px;

					.title_img {
						width: 22%;
						border-radius: 8%;

						@media (max-width: 1199.98px) {
							width: 25%;
							border-radius: 6.47px;
						}

						@media (max-width: 469px) {
							width: 22%;
						}
					}

					.desc_block {
						@media (max-width: 725px) {
							margin-bottom: 15px;
						}
						
						@media (max-height: 469px) {
							margin-bottom: 0;
						}

						.name {
							font-size: 32px;
							line-height: 36px;
							font-weight: 500;
							margin-bottom: 20px;

							@media (max-width: 1199.98px) {
								font-size: 24px;
								line-height: 28px;
							}

							@media (max-width: 725px) {
								font-size: 18px;
								line-height: 22px;
							}

							@media (max-width: 469px) {
								font-size: 12px;
								line-height: 16px;
								margin-bottom: 10px;
								text-transform: uppercase;
							}
						}

						.desc {
							font-size: 20.5px;
							line-height: 24px;
							font-weight: 500;
							font-family: 'MTSWideMedium';

							@media (max-width: 1199.98px) {
								font-size: 12px;
								line-height: 16px;
								text-transform: uppercase;
							}

							@media (max-width: 769px) {
								font-size: 10.5px;
								line-height: 13px;
							}

							@media (max-width: 469px) {
								font-size: 9.1px;
								line-height: 11.7px;
							}

							.desc_blue {
								background: linear-gradient(#5988C4, #576AAD);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
								background-clip: text;
							}

							.desc_white {
								color: #FAFAFA;
							}
						}

						.button {
							display: flex;
							width: 207px;

							@media (max-width: 1199.98px) {
								margin-top: 15px;
							}

							@media (max-width: 725px) {
								display: none;
							}
						}
					}
				}

				.right_block {
					color: rgba(250, 250, 250, 0.3);
					font-size: 24px;
					line-height: 28px;
					font-weight: 500;

					@media (max-width: 1199.98px) {
						font-size: 12px;
						line-height: 16px;
					}

					@media (max-width: 469px) {
						display: none;
					}
				}
			}
		}

		.default {
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;

			@media (max-width: 469px) {
				flex-direction: column-reverse;
				width: 100%;
			}

			img {
				width: 100%;
			}

			.default_text {
				display: flex;
				flex-direction: column;
				align-items: center;

				@media (max-width: 725px) {
					width: 100%;
					padding: 0 16px;
				}

				.d_desc {
					font-size: 36px;
					line-height: 40px;
					font-weight: 500;
					display: flex;
					flex-direction: column;
					align-items: center;

					@media (max-width: 1199.98px) {
						font-size: 24px;
						line-height: 28px;
					}

					@media (max-width: 725px) {
						font-size: 18px;
						line-height: 22px;
					}

					@media (max-width: 469px) {
						font-size: 12px;
						line-height: 16px;
						text-transform: uppercase;
					}

					.d_desc_blue {
						background: linear-gradient(#5988C4, #576AAD);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
					}

					.d_desc_white {
						color: #FAFAFA;
					}
				}

				.button {
					width: 207px;
					margin-top: 22px;
					display: flex !important;

					@media (max-width: 1199.98px) {
						margin-top: 15px;
						width: 241px;
					}

					@media (max-width: 469px) {
						width: 100%;
						height: 29px;
						min-height: 29px;
						max-height: 29px;
						font-size: 8px;
						line-height: 10px;
					}
				}
			}
		}
	}
}

.button {
	background: linear-gradient(144.11deg, #3C73B9 6.47%, #322882 127.1%);
	border-radius: 16px;

	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;

	font-style: normal;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	text-transform: uppercase;
	letter-spacing: 1.6px;

	color: #EEEEEE;
	box-shadow: none;
	border: none;
	padding: 10px 12px;
	margin-top: 20px;
	min-height: 44px;
	max-height: 44px;

	&:hover {
		cursor: pointer;
		background: linear-gradient(0deg, rgba(0, 29, 102, 0.3), rgba(0, 29, 102, 0.3)), linear-gradient(144.11deg, #3c73b9 6.47%, #322882 127.1%);
	}
}

// OLD BUTTON
.btn {
	cursor: pointer;
	z-index: 7;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	text-transform: uppercase;
	color: white;
	line-height: 16px;
	font-weight: 500;
	font-family: 'MTSWideMedium';
	position: relative;
	-webkit-border-radius: 16px;
	-moz-border-radius: 16px;
	border-radius: 16px;
	padding: 0 12px;

	&:before {
		z-index: -1;
		-webkit-border-radius: 16px;
		-moz-border-radius: 16px;
		border-radius: 16px;

		-webkit-transition-duration: 0.5s;
		-o-transition-duration: 0.5s;
		-moz-transition-duration: 0.5s;
		transition-duration: 0.5s;

		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;

		background: linear-gradient(180deg, #8E4383 0%, #95407A 45.3%, #922D60 45.31%, #B92E2D 100%),
			linear-gradient(270deg, rgba(180, 46, 45, 0.62) 10.94%, rgba(172, 59, 89, 0) 100%);
	}

	&:hover {
		-webkit-box-shadow: 4px 4px 20px 0px #3A298580;
		box-shadow: 4px 4px 20px 0px #3A298580;
		-webkit-box-shadow: 0px 4px 4px 0px #00000040;
		box-shadow: 0px 4px 4px 0px #00000040;
	}

	&:after {
		z-index: -2;
		-webkit-border-radius: 16px;
		-moz-border-radius: 16px;
		border-radius: 16px;

		position: absolute;
		content: '';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: -o-linear-gradient(163.73deg, #3973B9 -55.43%, #355DA8 -42.18%, #334093 -22.27%, #303189 -6.93%, #2E2B86 1.87%, #C40D71 62.99%, #F01A5D 90%);
		background: linear-gradient(286.27deg, #3973B9 -55.43%, #355DA8 -42.18%, #334093 -22.27%, #303189 -6.93%, #2E2B86 1.87%, #C40D71 62.99%, #F01A5D 90%);
	}

	&:hover:before {
		opacity: 0;
	}

	@media (max-width: 1199px) {
		font-size: 12px;
		line-height: 16px;
	}

	&.big-btn {
		font-size: 12px;
		line-height: 16px;
		border-radius: 4px;
		padding: 18px;

		@media (max-width: 1199px) {
			padding: 10px;
		}
	}
}

.disabled-button {
	pointer-events: none;
	opacity: 0.4;
}

h1 {
	font-family: 'MTSWideMedium';
	font-size: 44px;
	text-transform: none;
	line-height: 50px;
	margin-bottom: 50px;

	@media (max-width: 1199.98px) {
		font-size: 32px;
		line-height: 36px;
		margin-bottom: 16px;
	}

	@media (max-width: 725px) {
		font-size: 24px;
		line-height: 29px;
	}

	@media (max-width: 469px) {
		font-size: 17px;
		line-height: 22px;
	}
}

h2 {
	font-family: 'MTSCompactMedium';
	font-size: 34px;
	line-height: 36px;
	margin-bottom: 64px;
	color: black
}

h3 {
	font-family: 'MTSWideMedium';
	font-size: 24px;
	line-height: 28px;

	@media (max-width: 1199.98px) {
		font-size: 20px;
		line-height: 26px;
	}
}

.tariffs {
	position: relative;
	z-index: 999;

	@media (max-width: 725px) {
		overflow-x: auto;
		padding: 0 16px;
	}

	.wrapper {
		display: flex;
		flex-direction: column;
		gap: 31px;

		@media (max-width: 1199.98px) {
			gap: 16px;
		}

		@media (max-width: 725px) {
			width: max-content;
			gap: 12px;
		}

		h1 {
			margin-bottom: 0;
		}

		.benefits {
			display: flex;
			font-size: 24px;
			line-height: 32px;
			font-weight: 400;
			font-family: 'MTSTextRegular';

			@media (max-width: 1199.98px) {
				font-size: 17px;
				line-height: 24px;
			}

			@media (max-width: 725px) {
				display: flex;
				flex-direction: column;
			}
	
			.benefitsLeft {
				display: flex;
				flex-direction: column;
				width: 60%;

				@media (max-width: 725px) {
					width: 100%;
				}
			}
	
			.benefitsRight {
				display: flex;
				flex-direction: column;
				width: 40%;

				@media (max-width: 725px) {
					width: 100%;
				}
			}
		}

		.tariff_block {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 16px;
			overflow-x: auto;
	
			@media (max-width: 1199.98px) {
				gap: 12px;
			}
		
			@media (max-width: 725px) {
				justify-content: flex-start;
			}
		
			.tariff {
				border-radius: 24px;
				padding: 54px 24px;
				background: #1123424D;
				display: flex;
				flex-direction: column;
				justify-content: center;
				gap: 12px;
				width: calc(50% - 8px);
		
				@media (max-width: 1199px) {
					padding: 35px 24px;
				}
	
				@media (max-width: 725px) {
					padding: 24px;
					gap: 16px;
					aspect-ratio: 1/1;
					min-width: calc(50% - 8px);
					max-width: 60vw;
				}
		
				.audience {
					font-size: 24px;
					line-height: 28px;
					font-weight: 500;
					font-family: 'MTSWideMedium';
					color: rgba(250, 250, 250, 0.6);
		
					@media (max-width: 1199px) {
						font-size: 20px;
						line-height: 24px;
					}
		
					@media (max-width: 725px) {
						font-size: 14px;
						line-height: 16px;
						max-width: 121px;
						min-height: 32px;
						display: flex;
						align-items: center;
					}
				}
		
				.price_block {
					display: flex;
					flex-direction: row;
					align-items: flex-end;
					gap: 15px;
					flex-wrap: wrap;

					@media (max-width: 1199px) {
						gap: 12px;
					}
	
					@media (max-width: 425px) {
						flex-direction: column;
						align-items: flex-start;
						gap: 5px;
					}
		
					.discount_price {
						font-size: 56px;
						line-height: 56px;
						font-weight: 500;
						font-family: 'MTSWideMedium';
		
						@media (max-width: 1199px) {
							font-size: 36px;
							line-height: 40px;
						}
					}
		
					.full_price {
						display: table;
						position: relative;
						font-size: 24px;
						line-height: 28px;
						font-weight: 500;
						font-family: 'MTSWideMedium';
						color: rgba(250, 250, 250, 0.6);
						margin-bottom: 5px;
		
						@media (max-width: 1199px) {
							font-size: 20px;
							line-height: 24px;
							margin-bottom: 3px;
						}
		
						@media (max-width: 425px) {
							font-size: 24px;
							line-height: 28px;
							margin-bottom: 0;
						}
					}
		
					.full_price:before {
						position: absolute;
						content: '';
						background: #FF0032;
						width: 78px;
						height: 3px;
						top: 60%;
						left: 50%;
		
						-webkit-transform: translate(-50%, -50%) rotate(166.5deg);
						-ms-transform: translate(-50%, -50%) rotate(166.5deg);
						transform: translate(-50%, -50%) rotate(166.5deg);
					}

					.period {
						display: table;
						position: relative;
						font-size: 24px;
						line-height: 28px;
						font-weight: 500;
						font-family: 'MTSWideMedium';
						color: rgba(250, 250, 250, 0.6);
					}
				}
		
				.button {
					width: 207px;
					height: 44px;

					@media (max-width: 1199.98px) {
						width: 138px;
						height: 29px;
						min-height: 29px;
						max-height: 29px;
					}

					@media (max-width: 425px) {
						width: 115px;
						height: 34px;
						min-height: 34px;
						max-height: 34px;
						font-size: 9px;
						line-height: 12px;
						padding: 7.5px 9px;
						margin-top: 0;
					}
				}
			}
		}
	}
}

.subscription {
	@media (max-width: 725px) {
		padding: 0 16px;
	}

	.new_items_block {
		background: #1123424D;
		border-radius: 21px;
		padding: 24px 12px;
		display: flex;
		flex-direction: column;
		gap: 9px;
		overflow: hidden;

		@media (max-width: 1199.98px) {
			gap: 3px;
		}
	
		@media (max-width: 725px) {
			display: none;
		}
	
		h3 {
			margin-left: 12px;
			margin-bottom: 0;
		}
	
		.desc {
			font-size: 17px;
			line-height: 22px;
			font-weight: 400;
			margin-left: 12px;
			font-family: 'MTSCompactMedium';

			@media (max-width: 1199.98px) {
				font-size: 14px;
				line-height: 18px;
				font-family: 'MTSTextRegular';
			}
		}
	
		.new_items {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: flex-end;
			gap: 19px;
			height: 268px;
	
			.image_container {
				position: relative;
				height: 222px;
				width: 159px;
				transition: transform 0.3s ease, border 0.3s ease;
	
				@media (max-width: 1199px) {
					&:nth-child(n+5) {
						display: none;
					}
				}
	
				&:hover {
					transform: scale(1.2);
					border-radius: 11px;
					padding: 2.59px;
					background: linear-gradient(45deg, #E6012A, #E6012A, #BA1D7A, #6B14D7);
					bottom: 7%;
	
					img {
						border-radius: 7px;
						border: 3px solid #080e25;
						border-bottom: 0;
					}
				}
	
				img {
					width: 100%;
					height: 100%;
					border-radius: 5.35px;
				}
			}
		}
	}

	.subscription-container {
		margin-top: 31px;

		@media (max-width: 1199.98px) {
			margin-top: 10px;
		}

		@media (max-width: 725px) {
			overflow-x: auto;
		}

		.subscription-list {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			gap: 31px;
			height: 737px;

			@media (max-width: 1199.98px) {
				gap: 10px;
				height: 617px;
			}

			@media (max-width: 725px) {
				gap: 13px;
				height: 446px;
			}

			@media (max-width: 469px) {
				gap: 16px;
				height: 312px;	
			}

			.subscription-tile {
				position: relative;
				background: rgba(17, 35, 66, 0.3);
				border-radius: 21.08px;
				padding: 24px;
				overflow: hidden;

				@media (max-width: 1199.98px) {
					padding: 21px;
				}

				@media (max-width: 725px) {
					padding: 18px;
				}

				@media (max-width: 469px) {
					padding: 16px;
				}

				.blackout_circle {
					width: 604px;
					height: 604px;
					flex-shrink: 0;
					position: absolute;
					z-index: 99;

					@media (max-width: 1199.98px) {
						width: 368px;
						height: 368px;
					}

					@media (max-width: 725px) {
						width: 310px;
						height: 310px;
					}

					@media (max-width: 469px) {
						width: 252px;
						height: 252px;
					}
				}

				.blackout_circle::after {
					border-radius: 604px;
					background: var(--main-, radial-gradient(153.34% 141.75% at 104.54% 0%, #E7012B 4.49%, #A30A64 52.42%, #5615A4 100%));
					filter: blur(166.850830078125px);
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					pointer-events: none;

					@media (max-width: 1199.98px) {
						border-radius: 368px;
						filter: blur(101.65746307373047px);
					}

					@media (max-width: 725px) {
						border-radius: 310px;
						filter: blur(85.65746307373047px);
					}

					@media (max-width: 469px) {
						border-radius: 252px;
						filter: blur(69.61326599121094px);
					}
				}

				.tile1-bc {
					top: 28%;
					left: 48%;

					@media (max-width: 1199.98px) {
						top: 31%;
						left: 45%;
					}

					@media (max-width: 725px) {
						top: 33%;
						left: 51%;
					}

					@media (max-width: 469px) {
						top: 26%;
						left: 20%;
					}
				}

				.tile2-bc {
					top: -137%;
					left: 66%;

					@media (max-width: 1199.98px) {
						top: -170%;
						left: 57%;
					}

					@media (max-width: 725px) {
						top: 33%;
						left: 51%;
					}

					@media (max-width: 469px) {
						top: 26%;
						left: 20%;
					}
				}

				.tile3-bc {
					top: 61%;
					left: -58%;

					@media (max-width: 1199.98px) {
						top: 63%;
					}

					@media (max-width: 725px) {
						top: 33%;
						left: 51%;
					}

					@media (max-width: 469px) {
						top: 26%;
						left: 20%;
					}
				}

				.tile4-bc {
					top: -54%;
					left: -57%;

					@media (max-width: 1199.98px) {
						top: -43%;
						left: -58%;
					}

					@media (max-width: 725px) {
						top: 33%;
						left: 51%;
					}

					@media (max-width: 469px) {
						top: 26%;
						left: 20%;
					}
				}

				.tile5-bc {
					top: -137%;
					left: -28%;

					@media (max-width: 1199.98px) {
						top: -170%;
						left: -29%;
					}

					@media (max-width: 725px) {
						top: 33%;
						left: 51%;
					}

					@media (max-width: 469px) {
						top: 26%;
						left: 20%;
					}
				}

				.img-tile {
					position: absolute;
					z-index: 999;
				}

				.tile1-img {
					top: 25%;
					left: 1%;

					@media (max-width: 1199.98px) {
						top: 30%;
						left: -1%;
						scale: 0.95;
					}

					@media (max-width: 725px) {
						top: 25%;
						left: 0;
						scale: 0.9;
					}

					@media (max-width: 469px) {
						top: 30%;
						left: 0;
						scale: 1;
					}
				}

				.tile2-img {
					top: 30%;
					left: 37%;

					@media (max-width: 1199.98px) {
						top: 21%;
						left: 31%;
						scale: 0.6;
					}

					@media (max-width: 725px) {
						top: 35%;
						left: 30%;
						scale: 1;
					}

					@media (max-width: 469px) {
						top: 30%;
						left: 0;
						scale: 1;
					}
				}

				.tile3-img {
					top: 11%;
					left: 51%;

					@media (max-width: 1199.98px) {
						top: 24%;
						left: 16%;
						scale: 0.7;
					}

					@media (max-width: 725px) {
						top: 35%;
						left: 10%;
						scale: 1.2;
					}

					@media (max-width: 469px) {
						top: 40%;
						left: 0;
						scale: 1.2;
					}
				}

				.tile4-img {
					top: -4%;
					left: 62%;

					@media (max-width: 1199.98px) {
						top: 15%;
						left: 46%;
						scale: 0.9;
					}

					@media (max-width: 725px) {
						top: 25%;
						left: 35%;
						scale: 1.2;
					}

					@media (max-width: 469px) {
						top: 25%;
						left: 11%;
						scale: 1;
					}
				}

				.tile5-img {
					top: 15%;
					left: 47%;

					@media (max-width: 1199.98px) {
						top: 11%;
						left: 33%;
						scale: 0.5;
					}

					@media (max-width: 725px) {
						top: 33%;
						left: 10%;
						scale: 1;
					}

					@media (max-width: 469px) {
						top: 38%;
						left: 0;
						scale: 1;
					}
				}

				.tile-content {
					color: #FAFAFA;
					position: relative;
					z-index: 999;

					.tile-title {
						font-size: 24px;
						line-height: 28px;
						font-weight: 500;
						margin-bottom: 8px;

						@media (max-width: 1199.98px) {
							font-size: 20px;
							line-height: 26px;
							margin-bottom: 4px;
						}

						@media (max-width: 725px) {
						
						}

						@media (max-width: 469px) {
							font-size: 14px;
							line-height: 18px;	
						}
					}

					.desc-tile {
						font-size: 17px;
						line-height: 22px;
						font-weight: 400;
						font-family: 'MTSCompactRegular';

						@media (max-width: 1199.98px) {
							font-size: 14px;
							line-height: 18px;
							font-family: 'MTSTextRegular';
						}

						@media (max-width: 725px) {
						
						}

						@media (max-width: 469px) {
							font-size: 11px;
							line-height: 14px;
							font-family: 'MTSCompactRegular';	
						}
					}

					.tile1-desc {
						max-width: 85%;

						@media (max-width: 1199.98px) {
							max-width: 91%;
						}

						@media (max-width: 725px) {
							max-width: 100%;
						}
					}

					.tile2-desc {
						max-width: 100%;
					}

					.tile3-desc {
						max-width: 50%;

						@media (max-width: 1199.98px) {
							max-width: 95%;
						}

						@media (max-width: 725px) {
							max-width: 100%;
						}
					}

					.tile4-desc {
						max-width: 60%;

						@media (max-width: 1199.98px) {
							max-width: 82%;
						}

						@media (max-width: 725px) {
							max-width: 100%;
						}
					}

					.tile5-desc {
						max-width: 50%;

						@media (max-width: 1199.98px) {
							max-width: 75%;
						}

						@media (max-width: 725px) {
							max-width: 100%;
						}
					}
				}
			}

			.tile1-sub {
				height: calc(63% - 15.5px);
				width: calc(50% - 15.5px);

				@media (max-width: 1199.98px) {
					height: calc(70% - 5px);
					width: calc(50% - 5px);
				}

				@media (max-width: 725px) {
					height: 100%;
					width: 80%;
				}
			}

			.tile2-sub {
				height: calc(37% - 15.5px);
				width: calc(39% - 15.5px);

				@media (max-width: 1199.98px) {
					height: calc(30% - 5px);
					width: calc(39% - 5px);
				}

				@media (max-width: 725px) {
					height: 100%;
					width: 80%;
				}
			}

			.tile3-sub {
				height: calc(31.5% - 23.25px);
				width: calc(50% - 15.5px);

				@media (max-width: 1199.98px) {
					height: calc(35% - 7.5px);
					width: calc(50% - 5px);
				}

				@media (max-width: 725px) {
					height: 100%;
					width: 80%;
				}
			}

			.tile4-sub {
				height: calc(31.5% - 23.25px);
				width: calc(50% - 15.5px);

				@media (max-width: 1199.98px) {
					height: calc(35% - 7.5px);
					width: calc(50% - 5px);
				}

				@media (max-width: 725px) {
					height: 100%;
					width: 80%;
				}
			}

			.tile5-sub {
				height: calc(37% - 15.5px);
				width: calc(61% - 15.5px);
				margin-left: -119px;

				@media (max-width: 1199.98px) {
					height: calc(30% - 5px);
					width: calc(61% - 5px);
					margin-left: -11%;
				}

				@media (max-width: 725px) {
					height: 100%;
					width: 80%;
					margin-left: 0;
				}
			}
		}
	}
}

.footer {
	display: flex;
	flex-direction: column;
	background: rgba(4, 5, 24, 1);
	font-family: 'MTSCompactMedium';

	.text {
		font-size: 12px;
		line-height: 16px;
		font-weight: 400;
		color: #677F99;
		padding: 44px 0;

		@media (max-width: 725px) {
			padding: 24px 28px;
		}

		.footer-link {
			font-weight: normal;
			text-decoration: none;
			color: #677F99;
		}
	}
}
