.wrapper {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
  max-width: 40%;

  @media (max-width: 720px) {
	max-width: 90%;
  }
}

.title {
  text-align: center;
  font-size: 24px;
  line-height: 33px;
  margin-top: 35px;
  margin-bottom: 25px;
  max-width: 638px;
  color: white;

  @media (max-width: 1280px) {
    font-size: 17px;
    line-height: 20px;
    margin: 30px 0;
  }

  @media (max-width: 720px) and (max-height: 1152px) {
    margin: 50px 0 42px;
    line-height: 23px;
  }

  @media (max-width: 411px) {
    margin: 40px 0 38px;
    line-height: 22px;
  }

  @media (max-width: 360px) {
    font-size: 14px;
    line-height: 19px;
    margin: 24px 0 22px;
  }
}

.subtitle {
  font-weight: normal;
  font-size: 17px;
  text-align: center;
  line-height: 22px;
  margin: 0 0 16px;

  @media (max-width: 720px) and (max-height: 1152px) {
    line-height: 24px;
  }

  @media (max-width: 411px) {
    font-size: 14px;
    line-height: 19px;
  }
}

.icon {
  margin-bottom: 16px;
}

.label {
  margin: auto;
  line-height: 22px;
  text-align: center;
}

.app-ref-row {
  img {
    cursor: pointer;
    max-width: calc(100% / 3 - 4px);
  }

  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}

.kion-button {
  margin-bottom: 24px;
  width: 50%;
}