.err {
    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      justify-content: center;
    }
  
    &-msg {
      margin-bottom: 20px;
    }
  }
  .icon {
    margin-bottom: 20px;
  }